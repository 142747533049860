import React from 'react'
import { Link } from 'react-scroll';

type Props = {
  items: any
}

const Component: React.FC<Props> = props => {
  return (
    <ul>
      {
        props.items.map(item => (
          <li>
            <Link
              activeClass="active"
              to={item.url.replace('#', '')}
              spy={true}
              smooth={true}
              offset={0}
              duration={100}
            >{item.title}</Link>
            {item.items && (<Component items={item.items} />)}
          </li>
        ))
      }
    </ul>
  )
}

export default Component
