import React, { Children } from 'react'
import {
  FacebookShareButton,
  TwitterShareButton,
  LineShareButton,
  WeiboShareButton,
  FacebookIcon,
  TwitterIcon,
  LineIcon,
  WeiboIcon
} from 'react-share';

const styles = require('./share-buttons.module.css')

type Props = {
  url: string
}

const Component: React.FC<Props> = props => {
  const size = 32;
  return (
    <div className={styles.shareButtons}>
      <FacebookShareButton className={styles.button} url={props.url}>
        <FacebookIcon round={true} size={size} />
      </FacebookShareButton>
      <TwitterShareButton className={styles.button} url={props.url}>
        <TwitterIcon round={true} size={size}/>
      </TwitterShareButton>
      <LineShareButton className={styles.button} url={props.url}>
        <LineIcon round={true} size={size}/>
      </LineShareButton>
      <WeiboShareButton className={styles.button} url={props.url}>
        <WeiboIcon round={true} size={size}/>
      </WeiboShareButton>
    </div>
  )
}

export default Component