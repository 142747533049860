import React from 'react'
import Container from './new-container'
import Navigation from './navigation'
import { useLocation, WindowLocation } from '../../../gatsby-contentful-starter/node_modules/@types/reach__router';

const styles = require('./new-layout.module.css')

type Props = {
  location?: WindowLocation
  main?
  side?
}

const base = require('./base.css')

const Template: React.FC<Props> = props => {
  return (
    <Container className={styles.wrapper}>
      <Container className={styles.header}>
        <Navigation />
      </Container>
      <Container className={styles.main}>
        {props.main}
      </Container>
      <Container className={styles.side}>
        {props.side}
      </Container>
    </Container>
  )
}

export default Template
