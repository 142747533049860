import React from 'react'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import { useLocation } from '@reach/router';
import get from 'lodash/get'
import Img from 'gatsby-image'
import Layout from '../components/new-layout'
import ShareButtons from '../components/share-buttons'
import TOC from '../components/table-of-contents'

import { BlogPostBySlugQuery } from '../../types/graphql-types';

const heroStyles = require('../components/hero.module.css')
const styles = require('./blog-post.module.css')

type Props = {
  data: BlogPostBySlugQuery
}

const Component: React.FC<Props> = ({ data }) => {

  const post = data.contentfulBlogPost;
  const siteTitle = data.site.siteMetadata.title;
  const siteUrl = data.site.siteMetadata.siteUrl;
  const location = useLocation()

  return (
    <Layout location={location}
      main={
        <div style={{ background: '#fff' }}>
          <Helmet title={`${post.title} | ${siteTitle}`} />
          <div className={heroStyles.hero}>
            <Img
              className={heroStyles.heroImage}
              alt={post.title}
              fluid={post.heroImage.fluid}
            />
          </div>
          <div className="wrapper">
            <h1 className="section-headline">{post.title}</h1>
            <p
              style={{
                display: 'block',
              }}
            >
              {post.publishDate}
            </p>
            <div
              dangerouslySetInnerHTML={{
                __html: post.body.childMarkdownRemark.html,
              }}
            />
          </div>
          <ShareButtons url={`${siteUrl}/${post.slug}`} />
        </div>}
      side={
        <div className={styles.toc}>
          <TOC items={data.contentfulBlogPost.body.childMdx.tableOfContents.items} />
        </div>
      } />

  )
}

export default Component

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    contentfulBlogPost(slug: { eq: $slug }) {
      title
      publishDate(formatString: "MMMM Do, YYYY")
      heroImage {
        fluid(maxWidth: 1180, background: "rgb:000000") {
          ...GatsbyContentfulFluid_tracedSVG
        }
      }
      body {
        childMarkdownRemark {
          html
        }
        childMdx {
          tableOfContents
        }
      }
      slug
    }
  }
`
