import React from 'react'

type Props = {
  className?: string
}

const Component: React.FC<Props> = props => {
  return (
    <div className={props.className}>{props.children}</div>
  )
}

export default Component